.hero-contents {

    .title {
        width: 57%;
    }

    img {
        max-width: 330px;
        width: 100%;
    }
}

.splide__slide {
    h3 {
        margin-bottom: 0;
    }

    .slide-info {
        width: 80%;
    }
}

.slide-info {

    margin-bottom: 1rem;

    .quote {
        font-weight: bold;
        font-size: 1.4rem;
        line-height: 1.2;
        margin-bottom: 2rem;
    }

    .light {
        font-weight: light;
        margin-bottom: 0.2rem;
    }
}

.splide__slide img {
    box-shadow: none;
}

.quick-links {
    display: flex;
    gap: 2rem;

    a {
        width: 33.3%;
    }
}

.video-info {
    padding: 1rem !important;

    .pt-btn-style {
        margin-bottom: 0.5rem;
    }
}

// Header stuff
.dashboard_hero-section {
    background: none;
    padding-bottom: 2rem;
}

.dashboard_hero-section__checklist {


    .tintblue {
        margin-top: 2rem;
        padding: 0;
    }

    ul.two-col {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        list-style-position: inside;
        /* Ensures bullets/numbers are part of the content flow */
        list-style: disc;
        padding: 0;
        /* Removes default padding */
        margin-left: 2rem;

        li {
            font-size: 1rem;
            line-height: 1;
        }

    }
}

.account_details-item-container {

    .notification {
        display: inline-block;
        font-weight: bold;
        margin: 1rem 0 0 0;

        &.action-required {
            color: #e60b4b;

            &:before {
                font-family: 'FontAwesome';
                font-size: 1rem;
                font-weight: 700;
                content: "\f0f3";
                margin-right: 0.5rem;
            }

        }
    }
}

.video-embed {
    width: 100%;
    height: 540px;
    margin-bottom: 2rem;
}

.background-highlight {
    background: #f5f9ff;
}

.pt-tile-style {
    background: white;
    position: relative;
    padding: 2rem;
    margin: 0 1rem 1rem 1rem;

    h2 {
        padding: 0.5rem;
        display: inline-block;
        max-width: 300px;
    }

    .pt-style-inline {
        color: #1e1964;
        display: block;
        font-weight: bold;

        &:after {
            font-family: 'FontAwesome';
            font-size: 1rem;
            font-weight: 700;
            content: "\f061";
            margin-left: 0.5rem;
        }
    }

    .pt-style-btn {
        background-color: #1e1964;
        margin-bottom: 2rem;
        font-weight: bold;
        border: none;

        &:after {
            font-family: 'FontAwesome';
            font-size: 1rem;
            font-weight: 700;
            content: "\f054";
            margin-left: 1rem;
        }

        &:hover {
            color: #1e1964;
            background: #5cccff;
            text-decoration: none;

            &:after {
                color: white;
            }
        }

        &:focus {
            color: white;
        }
    }

    .account_details-icon-container {
        position: absolute;
        right: 2rem;
        top: 2rem;
        background: white;
        max-width: 55px;
    }

    &.tmbi {
        border-bottom: 5px solid #5cccff;

        h2 {
            background-color: #5cccff;
            color: #1E1964;
        }

        .pt-style-btn {
            &:hover {
                color: #1e1964;
                background: #5cccff;
            }
        }
    }

    &.sug {
        border-bottom: 5px solid #28D796;

        h2 {
            background-color: #28D796;
            color: #1E1964;
        }

        .pt-style-btn {
            &:hover {
                color: #1E1964;
                background: #28D796;
            }
        }
    }
}


/* Breadcrumbs */

.pt_breadcrumbs-container {
    padding: 1rem 0;
    height: 90px;

    ol.brdcrumbs {
        display: inline-flex;
    }

    li.bc-item {

        color: #1E1964;
    }

    li.bc-item.active {
        font-weight: bold;
    }

    .fa-angle-right {
        color: #c03;
        margin: 0 0.5rem;
    }

    .bc-icons-2 {
        margin: 1rem 0;


        .bc-icons-2 .cs-item.active {
            font-weight: bold;

        }


        .bc-icons-2 .cs-item+.cs-item::before {
            content: none;
        }
    }


}



/* Title*/

.hero-container .hero-contents h1 {
    font-size: 6rem;
    background: transparent !important;
    margin: 5px 0;
    font-weight: 400;
    text-transform: uppercase;
    font-family: "Druk",Arial,Helvetica,sans-serif;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    .header-first-line{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row; 
        padding: 0px 20px 0 0;
        color: #fff;
        margin-bottom: 20px;
        width: 100%;


        span{
            display: block;
            font-family: "Druk",Arial,Helvetica,sans-serif;
            font-size: 6rem;
            font-weight: 400;
            width: max-content;
            text-transform: uppercase;
            padding: 0px 20px;
            color: #fff;
        }

        svg{
            overflow: inherit;
        }
    }
    
    .header-second-line{
        font-family: "Druk",Arial,Helvetica,sans-serif;
        font-size: 6rem;
        width: 80%;
        text-transform: uppercase;
        padding: 0px 20px 0 0;
        font-weight: 400;
        box-sizing: border-box;
        color: #fff;
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 0.5rem;
    }

    span{
        font-family: "Druk",Arial,Helvetica,sans-serif;
    }

}


/* Round vetical */

.span-3-column {
    width: 16%;
    display: inline-grid;
    position: relative;
    top: 0rem;
    vertical-align: baseline;
    z-index: 0;




    .number-roundboder-content {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        border: 3px solid #333;
        vertical-align: middle;
        margin: 3rem 0;
        background-color: #fff;
    }

    .number-roundboder-content p {
        font-size: 1rem;
        padding: 2rem;
        font-weight: 800;
        text-align: center !important;

    }
}



.span-9-column {
    width: 80%;
    display: inline-grid;
    position: relative;
    top: 4.45rem;


    .tile.fith {
        width: auto;
        display: inline-grid;
        margin: 3.2rem 0;

    }

    .tile.fith:nth-child(2) {
        margin-top: 32px;
    }

    .tile.fith:nth-child(3) {
        margin-top: 34px;
    }

    .tile.fith:nth-child(4) {
        margin-top: 36px;
    }


}


/* Round vetical */


.vl {
    border-left: 3px solid #333;
    height: 656px;
    position: absolute;
    left: 62px;
    bottom: -33px;
    z-index: -1;
}

.tile.forth {
    width: 22%;
    display: inline-grid;
    text-align: left;
    margin: 0 10px;

    .number-roundboder-content {
        width: 7rem;
        height: 7rem;
        border-radius: 50%;
        border: 3px solid #333;
        vertical-align: middle;
        margin: auto;
        margin-left: 0;

        p {
            font-size: 3rem;
            padding: 1.5rem 1rem;
            text-align: center;
            margin: auto 0;
        }

    }

    .list-content {
        margin-top: 1.5rem;
    }


}





ul.tick li::before {
    content: '✓';
    color: red;
    font-size: 1.2rem;
    line-height: 30px;
    vertical-align: middle;
    font-weight: bold;
    margin-right: 1rem;
}


ul.tick li::marker {
    content: '';
}


ul li::marker {
    color: red;
    font-size: 1.6rem;
    line-height: 30px;

}


ul.tick li a {
    color: #0e7eb3;
    text-decoration: underline;
}


ul.tick li a:hover {
    color: #0e7eb3;

    text-decoration: none !important;
}


.tile.icon {
    width: 17%;
    display: inline-grid;
    margin: 10px;

    p {
        font-weight: 800;
    }
}


/* BIG Chverons */

.text-front {
    z-index: 2;
    position: relative;
}

svg.bigRed {
    position: absolute;
    z-index: 0;
    scale: 4.4;
    transform: translate(-38px, 13px);
}

svg.bigRed.second {
    scale: 5.55;
    transform: translate(-38px, 17px);
}


/* buttons */




.full-width-banner {
    text-align: left;
}

.full-width-banner ul li {
    font-size: 1.2rem;
    line-height: 30px;
}


.centered {
    text-align: center;
}



/* half-width */

.half-width.white-bk {
    box-shadow: 0px 0px 5px 5px #00000017;
}

.half-width.white-bk:hover,
.half-width.white-bk:hover .bottom-link {
    text-decoration: underline;
}

.half-width {
    width: 46%;
    margin: 0rem 1rem;
    display: inline-block;
    vertical-align: top;
    text-align: left;
    height: 410px;

    img.img-container {
        object-fit: fill;
    }

}

.white-bk {

    h3 {
        color: #fff;
        padding: 14px 1rem;
        line-height: 14px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 1rem;
        margin-top: 1rem;
        font-size: 1.2rem;
    }

    p {
        margin: 0 1rem;
        font-size: 1rem;
        color: #333;
    }

    .bottom-link {
        width: 90%;
        margin: 1rem auto 1rem 1rem;
        vertical-align: middle;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;

        .fa-chevron-right:before {
            color: #e60b4b;
        }

    }




}

.greeen {

    border-bottom: 5px solid #00857c;

    h3 {
        background-color: #00857c;
    }
}


.bluue {

    border-bottom: 5px solid #61c9fc;

    h3 {
        color: #1e1964;
        background-color: #61c9fc;
    }


}


.white.purple-bk {
    padding: 3rem 2rem;
}

.reduce {
    flex: 0 1 auto;
}


/* THIRD-width */

.third-width {
    width: 25%;
    display: inline-block;
    margin: 1rem 2rem;
    vertical-align: top;
}




/*  Colour Block */

.navy {
    color: #1E1964;
}

.navy-bk {
    background-color: #1E1964;
}

.green {
    color: #67D39B;
}



.green-bk {
    background-color: #67D39B;
}

.teal {
    color: #28625D;
}

.teal-bk {
    background-color: #28625D;
}

.lilac {
    color: #E8F3F2;
}

.lilac-bk {
    background-color: #F5F9FF;
}

.grey-bk {
    background-color: #f2f2f2;
}


.red-bk {
    background-color: #E50A4C;
}

.purple-bk {
    background-color: #411266;
}

.purple {
    color: #411266;
}




.pale-bk {
    background-color: #E8E7F9;
}

.white-bk {
    background-color: white;
}

.white {
    color: white;
}








/* Accordion */

button.js-concentina-toggle {
    background-color: #1e1964;
    color: white;
    padding: 8px 8px;
    border: none;
    font-size: 15px;
    display: inline-grid;
}




/* Buttons */

.link-btn {
    font-weight: bold;
    width: 50%;
    margin: 1rem 0;
    vertical-align: middle;
    color: #1e1964;
    background-color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    padding: 12px 17px;
    text-decoration: none;
}


button.js-concentina-toggle {
    background-color: #1e1964;
    color: white;
    padding: 8px 8px;
    border: none;
    font-size: 15px;
    display: inline-grid;
}

.three-col {
    display: flex;
    gap: 2rem;
    margin: 2rem 0;
    flex-direction: column;

    > div {
        // flex: 1;

        p {
            font-size: 1rem;

            strong {
                font-size: 1.2rem;
                display: block;
                margin-bottom: 0.5rem;
            }
        }
    }
}


@media handheld,
screen and (max-width: 768px) and (min-width: 320px) {

    // .three-col {
    //     display: block;
    // }

    .hero-contents {
        .title {
            width: 100%;
        }

    }

    .hero-contents .hero-content.title {

        h1 {
            font-size: 6rem;
            color: #fff;
            margin: 5px 0;
            font-weight: 400;
            margin-right: 12rem;
            text-transform: uppercase;
            font-family: "Druk",Arial,Helvetica,sans-serif;
            align-items: flex-start;
            justify-content: flex-start;
            flex-direction: column;
            gap: 0.5rem;
            width: 100%;
            display: flex !important;
            padding: 0px 10px 2px 0px;

            .header-first-line{
                padding: 0;
                margin-bottom: 0;
                span{
                    font-size: 3rem;
                    padding: 0 0 0 20px;
                }
            }

            .header-second-line{
                font-size: 3rem;
                padding: 0;
                margin: 0;
                height: auto;
                margin-bottom: 1rem;
            }


            svg.injected-svg {
                vertical-align: middle;
            }

            span {
                padding-left: 0;
            }


        }
    }



    .hero-container .hero-contents .hero-content {
        text-align: center;
    }

    .quick-links {
        display: block;

        a {
            width: 100%;
        }
    }



    .tile.fith {
        width: 100%;
        margin-bottom: 3rem;
    }


    .half-width,
    .third-width {
        width: 100%;
        margin: auto;
    }


    .half-width img.img-container {
        height: auto;
    }



    .tile.icon {
        width: 95%;
    }


    .pt-tile-style {

        .account_details-icon-container {
            position: relative;
            right: inherit;
            top: inherit;
            background: white;
            max-width: 100%;
            align-items: center;
            margin-top: 1rem;
            flex-direction: row;
            gap: 0.5rem;
        }
    }


}

@media screen and (max-width: 1300px) {
    .video-embed {
        height: 530px;
    }





}

@media screen and (max-width: 991px) {
    .video-embed {
        height: 400px;
    }

    .half-width{
        width: 100%;
        margin: auto;
        height: auto;
        margin-bottom: 2rem;
    }


    .half-width img.img-container {
        height: auto;
    }

    .tile.forth {
        width: 100%;
    }

    .tile.forth .number-roundboder-content p {
        margin: 0px;
    }

    .span-9-column {
        width: 75%;
        margin-left: 5%;
    }


}

@media (max-width: 767px) {
    .dashboard_hero-section__checklist {
        display: block;

        .header-actions__container {
            margin-top: 2rem;
        }
    }
}

@media screen and (max-width: 660px) {
    .video-embed {
        height: 330px;
    }
}

@media screen and (max-width: 550px) {
    .video-embed {
        height: 280px;
    }
}

.mobileView {
    display: none;
}

@media handheld,
screen and (max-width: 768px) and (min-width: 320px) {


    .hero-container .hero-contents {



        svg {
            width: 11%;
        }

        span {
            padding-left: 1rem;
        }

        h1 {
            font-size: 3rem;
            width: 100%;
            margin: 0px;
            margin-bottom: 0px;
            display: flex;
            justify-content: flex-start;
            flex-direction: row;
        }


        h1.second-Line {
            width: 90%;
            margin-bottom: 1rem;
        }

        p {
            text-align: left;

        }

        img {
            max-width: 150px;
            margin: auto;
            display: block;
        }

    }


    ul.tick {
        padding-left: 0;
        list-style: none !important;
        // padding-left: 1.4rem;
        margin-left: 1.5rem !important;
    }

    ul.tick li{
        position: relative;
    }

    ul.tick li::before {
        margin-right: 1rem;
        position: absolute;
        left: -1.5rem;
    }

    .full-width-banner ul li {
        margin-bottom: 1rem;
        line-height: 30px;
    }





    .full-width-banner h2 {
        font-size: 1.6rem;
    }


    svg.bigRed {

        display: none;
    }


    .full-width-banner.condensed {
        padding: 0rem 2rem;
    }


    .navy .full-width-banner h2,
    .navy .full-width-banner p {
        text-align: center;
    }


    .desktopView {
        display: none;
    }


    .mobileView.centered {
        text-align: left;
    }

    .mobileView {



        display: block;

        .number-roundboder-content {
            width: 8rem;
            height: 8rem;
            border-radius: 50%;
            border: 3px solid #333;
            vertical-align: middle;
            margin: 2rem 0rem;
            background-color: #fff;

            p {
                font-size: 1rem !important;
                padding: 2rem;
                font-weight: 800;
                text-align: center !important;
            }
        }


    }

    .tile.fith {
        width: 100%;
        margin-bottom: 3rem;
        text-align: left;
    }


    .tile.forth {
        width: 100%;
    }

    .tile.forth .number-roundboder-content p {
        margin: 0px;
    }

    .white-bk h3 {
        font-size: 1.2rem;
        line-height: inherit;
        height: 60px;
        margin-right: 1rem;
    }

    .half-width {
        min-height: 390px;
    }

    .half-width:last-child {
        margin-top: 1rem;
    }

    .concentina article header button {
        font-size: 1.3rem;
        line-height: normal;
    }



    .pt_breadcrumbs-container ol.brdcrumbs {
        display: inline-block;
        width: 100%;
        margin-left: 1rem;
    }


    li.bc-item {
        margin: 1rem 0;
        width: 100%;
        display: inline;
    }

    li.bc-item.active {
        display: inline-flex;
    }



    .bgIcon__Grey {
        display: none;
    }




}